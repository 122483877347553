import axios from "~/plugins/axios";
export default {
  // --- TIGROS REGISTRATION ---
  checkCard(user) {
    return axios
      .post("/ebsn/api/tigros-registration/check_user", user)
      .then(data => data.data.response)
      .catch(error => error);
  },
  findUser(user, checkAgreement) {
    return axios
      .get("/ebsn/api/tigros-registration/find_user", {
        params: {
          fidelity: user.fidelityCard,
          check_agreement: checkAgreement
        }
      })
      .then(data => {
        return data.data;
      });
  },

  findUserForTicket(fidelityCard, fiscalCode, firstName, lastName) {
    return axios
      .get("/ebsn/api/tigros-registration/find_user_for_ticket", {
        params: {
          fidelity_card: fidelityCard,
          fiscal_code: fiscalCode,
          first_name: firstName,
          last_name: lastName
        }
      })
      .then(data => {
        return data.data;
      });
  },

  registerUser(userData) {
    return axios
      .post("/ebsn/api/tigros-registration/register_user", userData)
      .then(response => {
        return response.data;
      });
  },

  updateUserDetail(user) {
    let url = "/ebsn/api/tigros-registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
      });
  },

  updateUserDataForTicket(user) {
    let url = "/ebsn/api/tigros-registration/update_user_data_for_ticket";
    return axios
      .post(url, user)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },

  updateUserTicketEnabled(
    fidelityCard,
    fiscalCode,
    firstName,
    lastName,
    enabled
  ) {
    let url = `/ebsn/api/tigros-registration/update_user_ticket_enabled?fidelity_card=${fidelityCard}&fiscal_code=${fiscalCode}&first_name=${firstName}&last_name=${lastName}&enabled=${enabled}`;
    return axios
      .post(url)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },

  // used to get bollette e garanzie da "I miei servivi"
  getReceiptList(
    initialDate,
    finalDate,
    importoDa,
    importoA,
    selectedStore,
    start,
    bollette,
    garanzie,
    receiptType,
    mergeAll,
    digital
  ) {
    if (!bollette) bollette = false;
    if (!garanzie) garanzie = false;
    let storeId = undefined;
    if (selectedStore) {
      storeId = selectedStore.code;
    }

    let params = {
      // fidelityCard:fidelityCard,
      from: initialDate,
      to: finalDate,
      importoDa: importoDa,
      importoA: importoA,
      puntoVendita: storeId,
      start: start,
      bollette: bollette,
      garanzie: garanzie
    };

    if (digital) {
      delete params["bollette"];
      delete params["garanzie"];
    }

    if (receiptType != 5) {
      params["receipt_type"] = receiptType;
    }
    if (mergeAll != null && mergeAll != undefined) {
      params["mergeAll"] = mergeAll;
    }

    return axios
      .get("/ebsn/api/tigros-registration/receipt_list", {
        params
      })
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },
  getReceiptDetail(receiptId) {
    return axios
      .get("/ebsn/api/tigros-registration/receipt_detail", {
        params: {
          receiptId: receiptId
        }
      })
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },
  getStores() {
    return axios
      .get("/ebsn/api/tigros-registration/store_list", {})
      .then(data => {
        return data.data.data;
      })
      .catch(err => err);
  },
  sendTicket(ticketData) {
    return axios
      .post("/ebsn/api/tigros-registration/send_ticket", ticketData)
      .then(response => {
        return response.data.response;
      });
  },
  setLotteryCode(code) {
    return axios
      .get("/ebsn/api/lottery/update-code", {
        params: {
          code: code
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  regenerateCard() {
    return axios
      .post("/ebsn/api/tigros-registration/regenerate_card")
      .then(response => {
        return response.data.response;
      });
  },
  getResetPasswordToken(email, fiscalCode, login) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email, fiscal_code: fiscalCode, login: login }
      })
      .then(data => {
        return data.data;
      });
  },

  updatePromovar(promovarCode, promovarValue) {
    let url = `/ebsn/api/tco/update_promovar?promovar_code=${promovarCode}&promovar_value=${promovarValue}`;
    return axios
      .put(url, {
        params: {
          promovar_code: promovarCode,
          promovar_value: promovarValue
        }
      })
      .then(data => {
        return data.data;
      });
  }

  // receiptDetail: { method: "GET", params: { action: "receipt_detail" }, cache: false },

  // to be implemented
  // searchUser: { method: "GET", params: { action: "find_user" }, cache: false },
  // register_user: { method: "POST", params: { action: "register_user" }, cache: false },
  // registration_confirm: { method: "GET", params: { action: "registration_confirm" }, cache: false },

  // updateUserTicketEnabled: { method: "POST", params: { action: "update_user_ticket_enabled" }, cache: false },
};
