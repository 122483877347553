import cartInfo from "~/mixins/cartInfo";

export default {
  mixins: [cartInfo],
  computed: {
    isCartaOroUser() {
      return this.getCrmSegmentId(1119) != null;
    },
    isCartaDipendentiUser() {
      return this.getCrmSegmentId(1120) != null;
    }
    // isCartaConvenzionataUser(){
    //   return this.getCrmSegmentId(1121) != null;
    // }
  }
};
